<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        
        <h5>Liste des commandes</h5>
        <Toolbar class="mb-4">
          <template v-slot:start>
            <div class="my-2">
              <Button
                label="Ajouter une commande"
                icon="pi pi-plus"
                class="p-button-success mr-2"
                @click="showAddCommand()"
              />
            </div>
          </template>
          
          <template v-slot:end>
          <Button  label="Exporter " icon="pi  pi-upload" chooselabel="exporter " @click="exportCSV()" class="mr-2 inline-block" />

            <Button
              type="button"
              label="Filtre"
              icon="pi pi-filter"
              @click="toggle"
              class="p-button-info"
            />
            <OverlayPanel
              ref="op"
              appendTo="body"
              :showCloseIcon="true"
              style="width: 600px"
            >
              <div class="card">
                <h5>Date</h5>
                <Calendar v-model="datefilter" selectionMode="range" />

                <!-- <Divider />
                <h5>Région</h5>
                <InputText
                  v-model="region"
                  type="text"
                /> -->

                <Divider />
                <h5>Caissier</h5>
                <Dropdown  v-model="selectedCiasse" style="width: 100% !important" :options="ciasses" filter optionLabel="full_name" placeholder="Choisir un Caissier" class="w-full md:w-14rem">
                  <template #value="slotProps" style="width: 100%">
                      <div v-if="slotProps.value" class="flex align-items-center">
                        
                          <div>{{ slotProps.value.full_name }}</div>
                      </div>
                      <span v-else>
                          {{ slotProps.placeholder }}
                      </span>
                  </template>
                  <template #option="slotProps" style="width: 100%">
                      <div class="flex align-items-center">
                          <div>{{ slotProps.option.full_name }}</div>
                      </div>
                  </template>
              </Dropdown>
                <!-- <Dropdown
                  v-model="selectedCiasse"
                  :options="ciasses"
                  optionLabel="full_name"
                  
                /> -->

                <Divider />
                <h5>Client</h5>
                <Dropdown  v-model="selectedclient" style="width: 100% !important" :options="clients" filter optionLabel="rsocial" placeholder="Choisir un Caissier" class="w-full md:w-14rem">
                  <template #value="slotProps" style="width: 100%">
                      <div v-if="slotProps.value" class="flex align-items-center">
                        <div v-if="slotProps.value.ice != null ">{{ slotProps.value.rsocial }}</div>
                        <div v-else>{{ slotProps.value.nom }} </div>
                      </div>
                      <span v-else>
                          {{ slotProps.placeholder }}
                      </span>
                  </template>
                  <template #option="slotProps" style="width: 100%">
                      <div class="flex align-items-center">
                        <div v-if="slotProps.option.ice != null">{{ slotProps.option.rsocial }}</div>
                        <div v-else>{{ slotProps.option.nom }}</div>
                      </div>
                  </template>
              </Dropdown>

                <!-- <Dropdown
                  v-model="selectedclient"
                  :options="clients"
                  optionLabel="rsocial"
                 
                /> -->
                <Divider />
                <h5>Mode de paiement</h5>
                <Dropdown
                  v-model="selectedmode_paiements"
                  :options="mode_paiements"
                  optionLabel="label"
                  
                />

                <Divider />
                <h5>statut de la commande</h5>

                <div class="p-fluid formgrid grid">
                  <div
                    v-for="status in statuts"
                    :key="status"
                    class="field col-12 md:col-6"
                  >
                    <div>
                      <Checkbox
                        id="checkOption1"
                        name="option"
                        :value="status.id"
                        v-model="statusValue"
                      />
                      <label for="checkOption1">{{ status.nomStatut }}</label>
                    </div>
                  </div>
                </div>
                <Button
                  label="appliquer"
                  @click="filter"
                  class="mr-2 mb-2 p-button-info"
                ></Button>
                <Button
                  label="exporter excel"
                  @click="excelit"
                  class="mr-2 mb-2 p-button-info"
                ></Button>
                <Button
                  label="Initialiser"
                  class="mr-2 mb-2"
                  @click="initfilterP"
                ></Button>
              </div>
            </OverlayPanel>
          </template>
        </Toolbar>
        <DataTable
          :value="orders"
          :totalRecords="totalItems"
          :first="firstRecordIndex"
          :lazy="true"
          @page="onPageChange"
          v-model:expandedRows="expandedRows"
          dataKey="id"
          @rowExpand="onRowExpand"
          @rowCollapse="onRowCollapse"
          responsiveLayout="scroll"
          :loading="loading1"
          :filters="filters1"
          :paginator="true"
          :paginatorPosition="'both'"
          class="p-datatable-gridlines"
          :rows="15"
          :rowHover="true"
          :globalFilterFields="[
            'client_id',
            'total',
            'is_regler',
            'modeDePaiement',
            'date_expidition',
            'id',
            'nomStatut',
            'nomStatut',
            'user_passe_commande',
            'rsocial',
            'nameClient'
          ]"
        >
          <template #header>
            <div class="flex justify-content-between flex-column sm:flex-row">
              <span class="p-input-icon-left mb-2">
                <i class="pi pi-search" />
                <InputText
                  v-model="clientSearch"
                  placeholder="Keyword Search"
                  style="width: 100%"
                  @keydown.enter="filter()"
                />
              </span>
              
            </div>
          </template>
          <template #empty> Aucun commande trouvé. </template>
          <template #loading>
            Chargement des données commandes. Veuillez patienter.
          </template>
          <Column :expander="true" headerStyle="width: 3rem" />
          <Column field="id" header="#ID" sortable>
            <template #body="slotProps">
              {{ slotProps.data.id }}
            </template>
          </Column>
          <Column field="rsocial" header="Client" sortable>
            <template #body="slotProps">
              
              <div v-if="slotProps.data.rsocial != null">
                {{ slotProps.data.rsocial }}
              </div>
              <div v-else>
                {{ slotProps.data.nameClient }}
              </div>
            </template>
          </Column>

          <Column field="total" header="Total" sortable>
            <template #body="slotProps">
              {{ slotProps.data.total }}
            </template>
          </Column>
          <Column field="nomStatut" header="Statut de la commande" sortable>
            <template #body="slotProps">
              {{ slotProps.data.nomStatut }}
            </template>
          </Column>
          <Column field="is_regler" header="Statuts de paiement" sortable>
            <template #body="slotProps">
              <div
                v-if="slotProps.data.is_regler == 1"
                class="success"
                style="color: green"
              >
                Reglé
              </div>
              <div v-else class="danger s">Non Reglé</div>
            </template>
          </Column>
          <Column
            field="user_passe_commande"
            header="Caissier "
            sortable
          ></Column>
          <Column
            field="modeDePaiement"
            header="Mode de paiement"
            sortable
          ></Column>

          <Column
            field="date_expidition"
            header="date d'expedition"
            sortable
          ></Column>
          
          <Column header="Actions">
            <template #body="data">
              <span class="p-buttonset">
                <!-- <Button
                  @click="showclientById(data.data.id)"
                  style="margin-right: 6px"
                  icon="pi pi-eye"
                  class="p-button-raised p-button-rounded p-button-success"
                /> -->
                <Button
                  title="Modifier la commande"
                  v-if="Permissions.update"
                  style="margin-right: 6px"
                  icon="pi pi-user-edit"
                  class="p-button-raised p-button-rounded p-button-info"
                  @click="updateOrder(data.data.id)"
                />
                <Button
                  title="afficher le bon de la commande"
                  v-if="Permissions.show"
                  style="margin-right: 6px"
                  icon="pi pi-file-pdf"
                  class="p-button-raised p-button-rounded"
                  @click="aficherBon(data.data.id)"
                />
                <Button
                  title="afficher le bon de la commande"
                  v-if="Permissions.show"
                  style="margin-right: 6px"
                  icon="pi pi-print"
                  severity="secondary"
                  class="p-button-raised p-button-rounded p-button-secondary"
                  @click="aficherBonimp(data.data.id)"
                />
                <Button
                  style="margin-right: 6px"
                  v-if="Permissions.addRet"
                  @click="showrefCmd(data.data.id)"
                  icon="pi pi-reply"
                  class="p-button-raised p-button-rounded p-button-warning"
                  title="Retour de la commande entière"
                />

                <Button
                  title="Supprimer commande"
                  v-if="Permissions.delete"
                  icon="pi pi-trash"
                  class="p-button-raised p-button-rounded p-button-danger"
                  @click="deleteOrder(data.data.id)"
                />
              </span>
            </template>
          </Column>
          <template #expansion="slotProps">
            <div class="orders-subtable">
              <h5>Produit de la commande № {{ slotProps.data.id }}</h5>
              <DataTable
                :value="slotProps.data.commandeLigne"
                responsiveLayout="scroll"
              >
                <Column field="nomProduit" header="Produit" sortable></Column>
                <Column
                  field="numSerie"
                  header="Numéro de série"
                  sortable
                ></Column>
                <Column field="qtt" header="quantité" sortable></Column>
                <Column field="prix" header="Prix" sortable>
                  <template #body="slotProps" sortable>
                    {{ parseFloat(slotProps.data.prix) }}
                  </template>
                </Column>
                <Column field="remise" header="Remise" sortable>
                  <template #body="slotProps" sortable>
                    {{ slotProps.data.remise }}
                  </template>
                </Column>
                <Column header="Image">
                  <template #body="slotProps">
                    <img
                      :src="
                        url +
                        slotProps.data.imageProduit
                      "
                      class="shadow-2"
                      width="100"
                    />
                  </template>
                </Column>
                <Column header="Actions">
                  <template #body="data">
                    <span class="p-buttonset">
                      <!-- <Button
                  @click="showclientById(data.data.id)"
                  style="margin-right: 6px"
                  icon="pi pi-eye"
                  class="p-button-raised p-button-rounded p-button-success"
                /> -->
                      <Button
                        style="margin-right: 6px"
                        v-if="Permissions.addRet"
                        @click="
                          showrefCmdLine(data.data, data.data.commande_id)
                        "
                        icon="pi pi-reply"
                        class="
                          p-button-raised p-button-rounded p-button-warning
                        "
                      />

                      <Button
                        v-if="Permissions.delete"
                        icon="pi pi-trash"
                        class="p-button-raised p-button-rounded p-button-danger"
                        @click="deleteline(data.data.id, data.data.commande_id)"
                      />
                    </span>
                  </template>
                </Column>
              </DataTable>
            </div>
          </template>
        </DataTable>
      </div>
    </div>
  </div>
  <Dialog v-model:visible="dialogOfCmdR">
    <template #header>
      <h3>Ajouter Retour de la commande № {{ returcmd.id }}</h3>
    </template>
    <div class="field col-12 md:col-6">
      <label for="address">Motif </label>
      <Textarea
        v-model="returcmd.note"
        id="address"
        rows="4"
        :class="{ 'is-invalid': returcmd.error }"
      />
      <small class="p-error" v-if="returcmd.error">
        {{ returcmd.error }}
      </small>
    </div>

    <Button
      label="Annuler"
      @click="dialogOfCmdR = false"
      icon="pi pi-times"
      class="p-button-text"
    />
    <Button
      label="Ajouter Retour"
      @click="ajouterRetour()"
      icon="pi pi-check"
    />
  </Dialog>
  <Dialog v-model:visible="dialogOfCmdRLine">
    <template #header>
      <h3>Ajouter Retour de la commande № {{ returcmdRLine.idcmd }}</h3>
    </template>
    <h5>Produit: {{ returcmdRLine.produit }}</h5>
    <div class="field col-12 md:col-6">
      <label for="address">Quantité retournée </label>
      <InputNumber v-model="returcmdRLine.qtt" :allowEmpty="false" :min="1" />
      <br />
      <small class="p-error" v-if="returcmdRLine.errorQtt">
        {{ returcmdRLine.errorQtt }}
      </small>
    </div>
    <div class="field col-12 md:col-6">
      <label for="address">Motif </label>
      <Textarea
        v-model="returcmdRLine.note"
        id="address"
        rows="4"
        :class="{ 'is-invalid': returcmdRLine.error }"
      />
      <small class="p-error" v-if="returcmdRLine.error">
        {{ returcmdRLine.error }}
      </small>
    </div>

    <Button
      label="Annuler"
      @click="dialogOfCmdRLine = false"
      icon="pi pi-times"
      class="p-button-text"
    />
    <Button
      label="Ajouter Retour"
      @click="ajouterRetourLine()"
      icon="pi pi-check"
    />
  </Dialog>
</template>

<script>
import moment from "moment";
import axios from "axios";
import { FilterMatchMode, FilterOperator } from "primevue/api";
export default {
  data() {
    return {
      totalItems:null,
      currentPage:1,
      perPage: 15,
      url:null,
      pdf:null,
      filters1: null,
      loading1: true,
      Permissions: {
        update: false,
        delete: false,
        add: false,
        show: false,
        addRet: false,
      },
      orders: null,
      expandedRows: [],
      dialogOfCmdR: false,
      returcmd: {
        id: null,
        note: null,
        error: null,
      },
      dialogOfCmdRLine: false,
      returcmdRLine: {
        id: null,
        note: null,
        qtt: null,
        qttReel: null,
        error: null,
        idcmd: null,
        produit: null,
      },

      ciasses: null,
      selectedCiasse: { id: null, full_name: "Tout" },
      statuts: null,
      stat: null,
      datedeb: null,
      datefin: null,
      clients: null,
      selectedclient: { id: null, rsocial: "Tout" , ice: "Tout", nom: "Tout" },
      statusValue: null,
      datefilter: [],
      // region: null,
      mode_paiements: null,
      selectedmode_paiements: null,
      clientSearch: null,
    };
  },
  productService: null,
  created() {
    this.initFilters1();
  },
  computed: {
  firstRecordIndex() {
    return (this.currentPage - 1) * this.perPage;
  }
},
  mounted() {
    this.url = this.$Gurl;
    // this.productService.getProductsWithOrdersSmall().then(data => this.products = data);
    this.Permissions.addRet =
      localStorage.permissionNames.includes("Ajouter retour");
    this.Permissions.update =
      localStorage.permissionNames.includes("Modifier commande");
    this.Permissions.delete =
      localStorage.permissionNames.includes("Supprimer commande");
    this.Permissions.add =
      localStorage.permissionNames.includes("Ajouter Commande");
    this.Permissions.show =
      localStorage.permissionNames.includes("Liste commande");
    if (this.Permissions.show) {
      this.filter();
    } else {
      this.$router.push("/");
    }
  },
  methods: {
    
    excelit(){
      
      moment(this.dateEx).format("YYYY-MM-DD");
      var filter = {
        user_passe_commande_id: null,
        client_id: null,
        status: null,
        dateD: null,
        dateF: null,
        // region: null,
        mode_paiements: null,
      };
      if (this.selectedCiasse.id!=null) {
        filter.user_passe_commande_id = this.selectedCiasse.id;
      } else {
        filter.user_passe_commande_id = "";
      }
      if (this.selectedclient.id) {
        filter.client_id = this.selectedclient.id;
      } else {
        filter.client_id = '';
      }
      if (this.statusValue) {
        filter.status = this.statusValue;
      } else {
        filter.status = [];
      }
      // if(this.region != null && this.region!=""){
      //   filter.region=this.region;
      // }else{
      //   filter.region="";
      // }
      if (this.datefilter.length > 0) {
        this.dateD = moment(this.datefilter[0]).format("YYYY-MM-DD");
        this.dateF = this.dateD ;
        if (this.datefilter[1] != null) {
        this.dateF = moment(this.datefilter[1]).format("YYYY-MM-DD");
        console.log(this.datefilter)
      }
        
      } else {
        this.dateD = '';
        this.dateF = '';
      }
      filter.dateD=this.dateD;
      filter.dateF=this.dateF;
      if (this.selectedmode_paiements) {
        filter.mode_paiements = this.selectedmode_paiements.id;
      } else {
        filter.mode_paiements = null;
      }
      console.log("filter",filter);
      axios
        .post("exportCommande", filter, {
        responseType: 'blob'
})
        .then((response) => {
          console.log(response);
          if (response.data.size==2) {
            this.$swal({
            icon: "error",
            title: "Aucune data",
            showConfirmButton: true,
          });
          }else{
            
          const url = URL.createObjectURL(new Blob([response.data], {
        type: 'application/vnd.ms-excel'
        }))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'fileName')
        document.body.appendChild(link)
        link.click()
          }
          
          
        })

    },
    exportCSV() {
			window.open(this.pdf, '_blank');
		},
    aficherBonimp(id) {
      this.$swal({
        title: "Voulez-vous afficher le prix dans le Bon de commande",
        showDenyButton: true,
        confirmButtonText: "Oui",
        denyButtonText: `Non`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          
          axios
            .post("telechargerbonneCommandeTickit/" + id, { afficherPrix: 1 })
            .then((response) => {
              console.log(response);
              const customAxios = axios.create({
            baseURL: 'http://localhost/ticket/ticket.php'
          });
              customAxios.post('/',JSON.stringify(response.data.date))
              .then(res => {
                // Handle the response
                console.log(res);
              })
              .catch(err => {
                // Handle the error
                console.log(err);
              });
              console.log('response!!!',axios.defaults.baseURL);
              
            })
            .catch(function (error) {
              console.log(error);
          //  if (error.response.status == 401) {
          //   localStorage.removeItem("permissionNames");
          //       localStorage.removeItem("user");
          //       localStorage.removeItem("token");
          //       window.location.replace("");
          //   // self.$router.push({ path: "/login" });
          //  // console.log(this.selectedCiasse);
          // }
        });
        } else {
          axios
            .post("telechargerbonneCommandeTickit/" + id, { afficherPrix: 0 })
            .then((response) => {
              console.log(response);
              const customAxios = axios.create({
            baseURL: 'http://localhost/ticket/ticket.php'
          });
              customAxios.post('/',JSON.stringify(response.data.date))
              .then(res => {
                // Handle the response
                console.log(res);
              })
              .catch(err => {
                // Handle the error
                console.log(err);
              });

            })
            .catch(function (error) {
              console.log(error);
          //  if (error.response.status == 401) {
          //   localStorage.removeItem("permissionNames");
          //       localStorage.removeItem("user");
          //       localStorage.removeItem("token");
          //       window.location.replace("");
          //   // self.$router.push({ path: "/login" });
          //  // console.log(this.selectedCiasse);
          // }
        });
        }
      });
    },
    aficherBon(id) {
      this.$swal({
        title: "Voulez-vous afficher le prix dans le Bon de commande",
        showDenyButton: true,
        confirmButtonText: "Oui",
        denyButtonText: `Non`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          axios
            .post("telechargerbonneCommande/" + id, { afficherPrix: 1 })
            .then((response) => {
              window.open(response.data.pdf, "_blank");
            })
            .catch(function (error) {
           if (error.response.status == 401) {
            localStorage.removeItem("permissionNames");
                localStorage.removeItem("user");
                localStorage.removeItem("token");
                window.location.replace("/");
            // self.$router.push({ path: "/login" });
           // console.log(this.selectedCiasse);
          }
        });
        } else {
          axios
            .post("telechargerbonneCommande/" + id, { afficherPrix: 0 })
            .then((response) => {
              window.open(response.data.pdf, "_blank");
            })
            .catch(function (error) {
           if (error.response.status == 401) {
            localStorage.removeItem("permissionNames");
                localStorage.removeItem("user");
                localStorage.removeItem("token");
                window.location.replace("/");
            // self.$router.push({ path: "/login" });
           // console.log(this.selectedCiasse);
          }
        });
        }
      });
    },
    showAddCommand() {
      this.$router.push({ name: "order" });
    },
    initfilterP() {
      this.datefilter = [];
      this.selectedCiasse = { id: null, full_name: "Tout" };
      this.selectedclient = { id: null, rsocial: "Tout" , ice: "Tout", nom: "Tout" };
      this.selectedmode_paiements = { id: null, rsocial: "Tout" };
      this.statusValue = null;
      // this.region = null;
      this.filter();
    },
    onPageChange(event) {
     this.currentPage = event.page+1 ; // Adjusting for 0-based indexing
    this.filter();
  },
    filter() {
      this.loading1 = true;


      moment(this.dateEx).format("YYYY-MM-DD");
      var filter = {
        user_passe_commande_id: null,
        client_id: null,
        status: null,
        dateD: null,
        dateF: null,
        // region: null,
        mode_paiements: null,
        recherche: null,
      };
      if (this.clientSearch != null && this.clientSearch!="") {
        filter.recherche = this.clientSearch;
      }
      if (this.selectedCiasse) {
        filter.user_passe_commande_id = this.selectedCiasse.id;
      } else {
        filter.user_passe_commande_id = null;
      }
      if (this.selectedclient.id) {
        filter.client_id = this.selectedclient.id;
      } else {
        filter.client_id = null;
      }
      if (this.statusValue) {
        filter.status = this.statusValue;
      } else {
        filter.status = null;
      }
      // if(this.region != null && this.region!=""){
      //   filter.region=this.region;
      // }
      if (this.datefilter.length > 0) {
        this.dateD = moment(this.datefilter[0]).format("YYYY-MM-DD");
        this.dateF = this.dateD ;
        if (this.datefilter[1] != null) {
        this.dateF = moment(this.datefilter[1]).format("YYYY-MM-DD");
        console.log(this.datefilter)
      }
      } else {
        this.dateD = null;
        this.dateF = null;
      }
      filter.dateD=this.dateD;
      filter.dateF=this.dateF;
      if (this.selectedmode_paiements) {
        filter.mode_paiements = this.selectedmode_paiements.id;
      } else {
        filter.mode_paiements = "";
      }
      console.log("filter",filter);
      
      axios
        .post("ListeCommandesClientRachid?page="+this.currentPage, filter)
        .then((response) => {
          console.log(response);
          this.orders = response.data.commandes;
          this.ciasses = response.data.users;
          this.ciasses.unshift({ id: null, full_name: "Tout" });
          this.statuts = response.data.statu;
          this.clients = response.data.client;
          this.clients.unshift({ id: null, rsocial: "Tout" , ice: "Tout", nom: "Tout" });
          this.mode_paiements = response.data.mode_paiements;
          this.mode_paiements.unshift({ id: null, label: "Tout" });
          this.totalItems = response.data.commande.total
          
          this.currentPage = response.data.commande.current_page

          this.loading1 = false;
          

          this.pdf=response.data.url;
        }).catch(function (error) {
           if (error.response.status == 401) {
            localStorage.removeItem("permissionNames");
                localStorage.removeItem("user");
                localStorage.removeItem("token");
                window.location.replace("/");
            // self.$router.push({ path: "/login" });
           // console.log(this.selectedCiasse);
          }
        });
      this.$refs.op.hide();
    },
    toggle(event) {
      this.$refs.op.toggle(event);
    },
    print() {
      window.print();
    },
    ajouterRetourLine() {
      var test = true;
      this.returcmdRLine.errorQtt = null;
      this.returcmdRLine.error = null;
      if (this.returcmdRLine.qtt > this.returcmdRLine.qttReel) {
        this.returcmdRLine.errorQtt =
          "la Quantité retournée doit d'etre inferieur de " +
          this.returcmdRLine.qttReel;
        test = false;
      }
      if (this.returcmdRLine.note == null || this.returcmdRLine.note == "") {
        this.returcmdRLine.error = "Motif du retour est obligatoire.";
        test = false;
      }
      if (test) {
        axios
          .post("RetourlignCommande/" + this.returcmdRLine.id, {
            qtt: this.returcmdRLine.qtt,
            note: this.returcmdRLine.note,
          })
          .then((response) => {
            console.log(response.data);
            this.$swal({
              icon: "success",
              title: response.data.message,
              showConfirmButton: false,
              timer: 1500,
            });
          })
          .catch(function (error) {
           if (error.response.status == 401) {
            localStorage.removeItem("permissionNames");
                localStorage.removeItem("user");
                localStorage.removeItem("token");
                window.location.replace("/");
            // self.$router.push({ path: "/login" });
           // console.log(this.selectedCiasse);
          }
        });
        this.dialogOfCmdRLine = false;
      }
    },
    showrefCmdLine(line, idcmd) {
      this.returcmdRLine.idcmd = idcmd;
      this.returcmdRLine.id = line.id;
      this.returcmdRLine.qttReel = line.qtt;
      this.returcmdRLine.qtt = line.qtt;
      this.returcmdRLine.error = null;
      this.returcmdRLine.note = null;
      this.returcmdRLine.produit = line.nomProduit;
      this.dialogOfCmdRLine = true;
    },
    ajouterRetour() {
      if (this.returcmd.note == null || this.returcmd.note == "") {
        this.returcmd.error = "Motif du retour est obligatoire.";
      } else {
        axios
          .post("RetourCommande/" + this.returcmd.id, {
            note: this.returcmd.note,
          })
          .then((response) => {
            console.log(response.data);
            this.$swal({
              icon: "success",
              title: response.data.message,
              showConfirmButton: false,
              timer: 1500,
            });
          })
          .catch(function (error) {
           if (error.response.status == 401) {
            localStorage.removeItem("permissionNames");
                localStorage.removeItem("user");
                localStorage.removeItem("token");
                window.location.replace("/");
            // self.$router.push({ path: "/login" });
           // console.log(this.selectedCiasse);
          }
        });
        this.dialogOfCmdR = false;
      }
    },
    showrefCmd(idcmd) {
      this.returcmd.id = idcmd;
      this.returcmd.error = null;
      this.returcmd.note = null;
      this.dialogOfCmdR = true;
    },
    updateOrder(id) {
      this.$router.push({ name: "updateorder", params: { id: id } });
    },
    deleteOrder(id) {
      this.$swal({
        icon: "warning",
        title: "Vous êtes sûr de vouloir continuer ?",
        showDenyButton: true,
        confirmButtonText: "supprimer",
        denyButtonText: `Annuler`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          axios
            .post("supprimerCommande/" + id)
            .then((response) => {
              var index = this.orders
                .map((x) => {
                  return x.id;
                })
                .indexOf(id);
              this.orders.splice(index, 1);
              console.log(response.data);
              this.$swal({
                icon: "success",
                title: response.data.message,
                showConfirmButton: false,
                timer: 1500,
              });
            })
            .catch(function (error) {
           if (error.response.status == 401) {
            localStorage.removeItem("permissionNames");
                localStorage.removeItem("user");
                localStorage.removeItem("token");
                window.location.replace("/");
            // self.$router.push({ path: "/login" });
           // console.log(this.selectedCiasse);
          }
        });
        }
      });
    },
    deleteline(id, idcmd) {
      this.$swal({
        icon: "warning",
        title: "Vous êtes sûr de vouloir continuer ?",
        showDenyButton: true,
        confirmButtonText: "supprimer",
        denyButtonText: `Annuler`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          axios
            .post("supprimerLigneCommande/" + id)
            .then(() => {
              var index = this.orders
                .map((x) => {
                  return x.id;
                })
                .indexOf(idcmd);
              var index2 = this.orders[index].commandeLigne
                .map((x) => {
                  return x.id;
                })
                .indexOf(id);
              this.orders[index].commandeLigne.splice(index2, 1);
              console.log(this.produits);
              this.$swal({
                icon: "success",
                title: "Supprimé",
                showConfirmButton: false,
                timer: 1500,
              });
            })
            .catch(function (error) {
           if (error.response.status == 401) {
            localStorage.removeItem("permissionNames");
                localStorage.removeItem("user");
                localStorage.removeItem("token");
                window.location.replace("/");
            // self.$router.push({ path: "/login" });
           // console.log(this.selectedCiasse);
          }
        });
        }
      });
    },
    showAllCmd() {
      axios
        .get("ListeCommandesClientRachid")
        .then((response) => {
          console.log(response);
          this.orders = response.data.commandes;
          this.ciasses = response.data.users;
          this.ciasses.unshift({ id: null, full_name: "Tout" });
          this.statuts = response.data.statu;
          this.clients = response.data.client;
          this.clients.unshift({ id: null, rsocial: "Tout" , ice: "Tout", nom: "Tout" });
          this.mode_paiements = response.data.mode_paiements;
          this.mode_paiements.unshift({ id: null, label: "Tout" });
          

          this.pdf=response.data.url;
          this.loading1 = false;
        })
        .catch(function (error) {
           if (error.response.status == 401) {
            localStorage.removeItem("permissionNames");
                localStorage.removeItem("user");
                localStorage.removeItem("token");
                window.location.replace("/");
            // self.$router.push({ path: "/login" });
           // console.log(this.selectedCiasse);
          }
        });
    },
    onRowExpand(event) {
      this.$toast.add({
        severity: "info",
        summary: "Product Expanded",
        detail: event.data.name,
        life: 3000,
      });
    },
    onRowCollapse(event) {
      this.$toast.add({
        severity: "success",
        summary: "Product Collapsed",
        detail: event.data.name,
        life: 3000,
      });
    },
    expandAll() {
      this.expandedRows = this.products.filter((p) => p.id);
      this.$toast.add({
        severity: "success",
        summary: "All Rows Expanded",
        life: 3000,
      });
    },
    collapseAll() {
      this.expandedRows = null;
      this.$toast.add({
        severity: "success",
        summary: "All Rows Collapsed",
        life: 3000,
      });
    },
    initFilters1() {
      this.filters1 = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        "country.name": {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        representative: { value: null, matchMode: FilterMatchMode.IN },
        date: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
        },
        balance: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
        },
        status: {
          operator: FilterOperator.OR,
          constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
        },
        activity: { value: null, matchMode: FilterMatchMode.BETWEEN },
        verified: { value: null, matchMode: FilterMatchMode.EQUALS },
      };
    },
    clearFilter1() {
      this.initFilters1();
    },
  },
};
</script>